import { Link } from "react-router-dom"

const Card = ({course}) => {
  let {title, category, tier, description,_id, expected_duration } = course   
  
  return (
  <Link to={`/courses/${_id}`}>
  <div className="shadow-lg p-5 flex flex-col bg-FarmWhite rounded-lg transition ease-in-out hover:scale-105 duration-300 font-mono">
      <div className="font-bold text-center text-lg text-FarmNavy"><span className="text-FarmLime">{category}</span> ({tier})</div>
    
        <div>Title: {title}</div>
        <div>Category: <span className="font-semibold text-orange-600">{category}</span></div>
        <div>Tier: {tier}</div>
        <div>Description: {description}</div>
        <div>Expected duration: {expected_duration}</div>

  </div>
  </Link>
)
}
export default Card