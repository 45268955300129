import Card from "../components/Card"
import Loading from "../components/Loading"
import {useState, useEffect} from 'react'
import courseService from '../services/CourseService'; // Import the service


const Courses = () => {

    const [courses, setCourses] = useState([])
    const [category, setCategory] = useState('')
    const [isPending, setIsPending] = useState(true)
    const [page, setPage] = useState(1)

    const handleChangeCategory = (ev) => {
        setCourses([])        
        setCategory(ev.target.value)
        setIsPending(true)
    }

    const handleChangePage = (ev) => {
        setCourses([])        
        setPage(ev.target.value)
        setIsPending(true)
    }


    const fetchCourses = async () => {
        setIsPending(true);
        try {
            const fetchedCourses = await courseService.getList({
                category: category || undefined, // Pass category only if it's not empty
                page,
            });
            setCourses(fetchedCourses);
        } catch (error) {
            console.error('Error fetching courses:', error);
            // Handle error state if needed
        } finally {
            setIsPending(false);
        }
    };

    useEffect(() => {
        fetchCourses();
    }, [category, page]); 
    
   return (
        <div>
            <h2 className="font-bold font-mono text-lg text-center my-4">Courses - {category?category:"all categories"}</h2>
            <div className="mx-8">
            <label htmlFor="courses">Choose a category: </label>
                <select name="courses" id="courses" onChange={handleChangeCategory}>
                    <option value="">All courses</option>
                    <option value="Coding">Coding</option>
                </select>
                <label htmlFor="courses">Choose a page: </label>
                <select name="page" id="page" onChange={handleChangePage}>
                    <option value="1">1</option>
                    <option value="2">2</option>           
                </select>
            </div>
            <div className="mx-8">                
                {isPending && <Loading category={category} />}             
                    <div className="grid grid-cols-2 gap-3 lg:grid-cols-2">
                        {courses && courses.map(
                        (el)=>{
                            return (                               
                                    <Card key={el._id} course = {el} />                           
                            )
                        }
                        )}
                    </div>
                
            </div> 
        </div>
    )
}

export default Courses