import React from 'react'

const Register = () => {
  return (
    <div className="w-3/3 shadow-xl min-h-max flex flex-col items-center justify-center">
     <h2 className="text-xl text-primary text-center font-bold">Register</h2>
     <p>The app is not ready yet and registration is not opened for public, please contact contact@astracoders.com for details.</p>
    </div>
  )
}

export default Register