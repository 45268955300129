import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div>
      <div className="hero flex-1 bg-lightblue">
        <div className="hero-overlay bg-opacity-60"></div>
        <div className="hero-content text-neutral-content bg-opacity-80">
          <div className="max-w-5xl mx-auto">
            <h1 className="mb-5 text-5xl font-bold">
              Learn python programming with easy context for{' '}
              <span className="text-lightblue">all age groups starting from 6.</span>.
            </h1>
            <p className="mb-5 text-3xl">
              Our in-depth courses teach you how to program python to solve problems.
            </p>
            <Link to="/courses" className="btn btn-secondary">
              Get Started
            </Link>
          </div>
        </div>
      </div>

      <div className="flex-1 mt-10">
        <div className="characters-container flex justify-center">
          <div className="character-card mx-2 transform transition-transform duration-300 hover:translate-y-[-10px] hover:shadow-lg">
            <img className="w-full rounded-lg" src={`${process.env.PUBLIC_URL}/characters/novice.jpg`} alt="Novice" />
            <h3 className="text-center mt-2 text-xl text-gray-800">Novice</h3>
          </div>
          <div className="character-card mx-2 transform transition-transform duration-300 hover:translate-y-[-10px] hover:shadow-lg">
            <img className="w-full rounded-lg" src={`${process.env.PUBLIC_URL}/characters/apprentice.jpg`} alt="Apprentice" />
            <h3 className="text-center mt-2 text-xl text-gray-800">Apprentice</h3>
          </div>
          <div className="character-card mx-2 transform transition-transform duration-300 hover:translate-y-[-10px] hover:shadow-lg">
            <img className="w-full rounded-lg" src={`${process.env.PUBLIC_URL}/characters/adept.jpg`} alt="Adept" />
            <h3 className="text-center mt-2 text-xl text-gray-800">Adept</h3>
          </div>
          <div className="character-card mx-2 transform transition-transform duration-300 hover:translate-y-[-10px] hover:shadow-lg">
            <img className="w-full rounded-lg" src={`${process.env.PUBLIC_URL}/characters/veteran.jpg`} alt="Veteran" />
            <h3 className="text-center mt-2 text-xl text-gray-800">Veteran</h3>
          </div>
          <div className="character-card mx-2 transform transition-transform duration-300 hover:translate-y-[-10px] hover:shadow-lg">
            <img className="w-full rounded-lg" src={`${process.env.PUBLIC_URL}/characters/master.jpg`} alt="Master" />
            <h3 className="text-center mt-2 text-xl text-gray-800">Master</h3>
          </div>
          <div className="character-card mx-2 transform transition-transform duration-300 hover:translate-y-[-10px] hover:shadow-lg">
            <img className="w-full rounded-lg" src={`${process.env.PUBLIC_URL}/characters/legend.jpg`} alt="Legend" />
            <h3 className="text-center mt-2 text-xl text-gray-800">Legend</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;