import React from 'react'

const Footer = () => {
  return (
<footer className="footer footer-center p-4 bg-base-300 text-base-content">
  <div>
    <p>Copyright © 2024 - All right reserved by Shion</p>
  </div>
</footer>
  )
}

export default Footer
