import {Route, Routes} from "react-router-dom"


import Layout from "./components/Layout";
import HomePage from "./components/HomePage";
import Login from "./components/Login";
import Register from "./components/Register";
import NotFound from "./components/NotFound";
import RequireAuthentication from "./components/RequireAuthentication";

import Courses from './pages/Courses'
import Codeclass from './pages/Codeclass'

function App() {  

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="login" element={<Login/>} />
        <Route path="register" element={<Register/>} />
        <Route path="courses" element={<Courses/>} />

        <Route element={<RequireAuthentication />}>
          <Route path="courses/:id" element={<Codeclass />} />
          <Route path="*" element={<NotFound/>} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;