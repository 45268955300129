import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import './App.css'; // Import your App.css here


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>       
      </AuthProvider>    
    </BrowserRouter>        
  </React.StrictMode>
);


// import React from 'react';
// import ReactDOM from 'react-dom/client';

// import {
//   BrowserRouter,
//   Routes,
//   Route,
// } from "react-router-dom";


// import Courses from './pages/Courses'
// import Codeclass from './pages/Codeclass'

// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(

//   <React.StrictMode>
//     <BrowserRouter>
//       <Routes>
//         <Route path="/*" element={<App />} />
//         <Route path="courses" element={<Courses />} />
//         <Route path="courses/:id" element={<Codeclass />} />
//         <Route path="*" element={
//           <main style={{ padding: "1rem" }}>
//             <p>There's nothing here!</p>
//           </main>
//         }
//         />
//       </Routes>
//     </BrowserRouter>
//   </React.StrictMode>

// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
